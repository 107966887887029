import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import '../components/all.sass'

export default function SoftwareConfigurationIdentificationForm({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <div>
      <Layout title={documentName} filePath={filePath}>
        <section className="section sectionbody">
          <div className="">
            <table className="table is-bordered is-striped is-narrow is-hoverable">
              <tbody>
                <tr>
                  <td>Software Description: </td>
                  <td>{frontmatter.softwareDescription}</td>
                </tr>

                <tr>
                  <td>Software Sytem Configuration: </td>
                  <td>{frontmatter.softwareSytemConfiguration}</td>
                </tr>

                <tr>
                  <td>Document ID: </td>
                  <td>{frontmatter.documentID}</td>
                </tr>

                <tr>
                  <td>Document Version: </td>
                  <td>{frontmatter.documentVersion}</td>
                </tr>

                <tr>
                  <td>Template ID: </td>
                  <td>{frontmatter.templateID}</td>
                </tr>

                <tr>
                  <td>Template Version: </td>
                  <td>{frontmatter.templateVersion}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div className=""></div>

        <section className="section sectionbody">
          <div className="">
            <h2 className="title has-text-info">Configuration Items</h2>
            <table className="table is-bordered is-striped is-narrow is-hoverable">
              <thead>
                <th>Item Name</th>
                <th>Document ID</th>
                <th>Revision</th>
                <th>Document Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>Firmware Binary</td>
                  <td>{frontmatter.firmwareBinary.id}</td>
                  <td>{frontmatter.firmwareBinary.idRevision}</td>
                  <td>{frontmatter.firmwareBinary.docDescription}</td>
                </tr>
                <tr>
                  <td>Firmware Update</td>
                  <td>{frontmatter.firmwareUpdateID}</td>
                  <td>{frontmatter.firmwareUpdateRevision}</td>
                  <td>{frontmatter.firmwareUpdateDocDes}</td>
                </tr>
                <tr>
                  <td>Graphics Hex</td>
                  <td>{frontmatter.graphicsHex.id}</td>
                  <td>{frontmatter.graphicsHex.idRevision}</td>
                  <td>{frontmatter.graphicsHex.docDescription}</td>
                </tr>

                <tr>
                  <td>Graphics Update</td>
                  <td>{frontmatter.graphicsUpdate.id}</td>
                  <td>{frontmatter.graphicsUpdate.idRevision}</td>
                  <td>{frontmatter.graphicsUpdate.docDescription}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section className="section sectionbody">
          <div className="">
            <h2 className="title has-text-info">SOUP Identification</h2>
            <table className="table">
              <thead>
                <tr>
                  <td>Title</td>
                  <td>Manufacturer</td>
                  <td>SOUP Designator</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{frontmatter.soupTitle}</td>
                  <td>{frontmatter.soupManufacturer}</td>
                  <td>{frontmatter.soupDesignator}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query MarkdownQuerySoftwareConfigurationIdentificationPlan(
    $filePath: String
  ) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        softwareDescription
        softwareSytemConfiguration
        documentID
        documentVersion
        templateID
        templateVersion

        firmwareBinary {
          name
          id
          idRevision
          docDescription
        }

        firmwareUpdate {
          name
          id
          idRevision
          docDescription
        }

        graphicsHex {
          name
          id
          idRevision
          docDescription
        }

        graphicsUpdate {
          name
          id
          idRevision
          docDescription
        }

        soup {
          name
          identifier
          manufacturer
        }
      }
    }
  }
`
